import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { socket } from "../../socket";

const LANGUAGE_OPTIONS = [
  { key: "Italien", value: "it" },
  { key: "French", value: "fr" },
  { key: "English", value: "en" },
];

const DEFAULT_FONT_SIZE = 14;
const SCROLL_RESUME_DELAY = 2000;
const MIN_CLEAR_INTERVAL = 10;
const MAX_CLEAR_INTERVAL = 110;

export default function ViewerLandingPage() {
  // State management
  const [state, setState] = useState({
    language: "Select a language",
    chosenLanguage: null,
    streaming: false,
    unknownBroadcast: false,
    fontSize: DEFAULT_FONT_SIZE,
    isManualScrolling: false,
    clearTextInterval: 5000,
    message: "",
  });

  // Refs
  const refs = {
    lang: useRef(null),
    totalMsg: useRef(""),
    textarea: useRef(null),
    manualScrollTimeout: useRef(null),
    clearTextTimeout: useRef(null),
  };

  const { id: roomId } = useParams();

  // Auto-scroll effect
  useEffect(() => {
    const textarea = refs.textarea.current;
    if (!textarea || state.isManualScrolling) return;

    textarea.scrollTop = textarea.scrollHeight;
  }, [state.message, state.isManualScrolling]);

  // Clear text timer effect
  useEffect(() => {
    if (state.clearTextInterval > 100) return;

    clearTimeout(refs.clearTextTimeout.current);

    refs.clearTextTimeout.current = setTimeout(() => {
      refs.totalMsg.current = "";
      setState((prev) => ({ ...prev, message: "" }));
    }, state.clearTextInterval);

    return () => clearTimeout(refs.clearTextTimeout.current);
  }, [state.message, state.clearTextInterval]);

  // Stream status effect
  useEffect(() => {
    const checkStreamStatus = async () => {
      try {
        const response = await fetch(`/api/stream_status/${roomId}`, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        });

        const data = await response.json();
        setState((prev) => ({
          ...prev,
          streaming: data.result,
          unknownBroadcast: !response.ok,
        }));
      } catch (err) {
        setState((prev) => ({ ...prev, unknownBroadcast: true }));
        console.error("Stream status check failed:", err);
      }
    };

    checkStreamStatus();
  }, [roomId]);

  // Socket translation effect
  useEffect(() => {
    if (!socket) {
      console.error("Socket connection not established");
      return;
    }

    const handleTranslation = (data) => {
      try {
        if (!data?.privMap?.privKeys || !data?.privMap?.privValues) {
          console.warn("Invalid translation data format");
          return;
        }

        const languageIndex = data.privMap.privKeys.indexOf(refs.lang.current);
        if (languageIndex === -1) {
          console.warn(`Language "${refs.lang.current}" not found`);
          return;
        }

        const translatedMessage = data.privMap.privValues[languageIndex];
        if (!translatedMessage) return;

        const updatedMessage =
          refs.totalMsg.current +
          (refs.totalMsg.current ? "\n" : "") +
          translatedMessage;

        refs.totalMsg.current = updatedMessage;

        setState((prev) => ({
          ...prev,
          message: updatedMessage
            .split(/\r?\n/)
            .filter((line) => line.trim())
            .join("\n"),
        }));
      } catch (error) {
        console.error("Translation processing error:", error);
      }
    };

    socket.on("translated", handleTranslation);
    return () => socket.off("translated", handleTranslation);
  }, []);

  // Event handlers
  const handleScroll = () => {
    clearTimeout(refs.manualScrollTimeout.current);
    setState((prev) => ({ ...prev, isManualScrolling: true }));

    refs.manualScrollTimeout.current = setTimeout(() => {
      setState((prev) => ({ ...prev, isManualScrolling: false }));
    }, SCROLL_RESUME_DELAY);
  };

  const handleLanguageSelect = () => {
    refs.lang.current = state.language;
    setState((prev) => ({ ...prev, chosenLanguage: state.language }));
    socket.emit("join", { room: roomId, language: state.language });
  };

  const handleLanguageChange = () => {
    refs.totalMsg.current = "";
    setState((prev) => ({
      ...prev,
      message: "",
      chosenLanguage: null,
    }));
    socket.emit("leave", { room: roomId });
  };

  const handleFontSizeChange = (delta) => {
    setState((prev) => ({
      ...prev,
      fontSize: prev.fontSize + delta,
    }));
  };

  const handleClearIntervalChange = (event) => {
    setState((prev) => ({
      ...prev,
      clearTextInterval: event.target.value * 1000,
    }));
  };

  // Render helpers
  const renderControls = () => (
    <div className="columns is-centered is-multiline">
      <div className="column is-narrow is-flex-direction-row mt-5">
        <button
          className="button is-primary is-outlined"
          onClick={() => handleFontSizeChange(1)}
        >
          <p className="montserrat-text-logo">FONT SIZE +</p>
        </button>
        <button
          className="button is-primary is-outlined ml-1"
          onClick={() => handleFontSizeChange(-1)}
        >
          <p className="montserrat-text-logo">FONT SIZE -</p>
        </button>
      </div>

      <div className="column is-narrow">
        <div className="control">
          <p className="montserrat-body">Interval before clearing text:</p>
          <div>
            {" "}
            <input
              className="slider is-fullwidth"
              type="range"
              min={MIN_CLEAR_INTERVAL}
              max={MAX_CLEAR_INTERVAL}
              step="10"
              value={state.clearTextInterval / 1000}
              onChange={handleClearIntervalChange}
            />
          </div>

          <output>
            {state.clearTextInterval / 1000 > 100
              ? "No clear"
              : `${state.clearTextInterval / 1000} seconds`}
          </output>
        </div>
      </div>
    </div>
  );

  const renderTextArea = () => (
    <div className="column">
      <div className="section">
        <textarea
          ref={refs.textarea}
          style={{
            fontSize: `${state.fontSize}px`,
            minHeight: "50vh",
            whiteSpace: "pre-wrap",
          }}
          className="textarea is-large"
          value={state.message}
          readOnly
          onWheel={handleScroll}
          onTouchStart={handleScroll}
          onMouseDown={handleScroll}
        />
      </div>
    </div>
  );

  const renderLanguageSelect = () => (
    <div className="section is-large">
      <div className="columns is-multiline is-mobile is-centered">
        <div className="select is-primary">
          <select
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                language: e.target.value,
              }))
            }
          >
            <option value="Select a language">Select a language</option>
            {LANGUAGE_OPTIONS.map((option) => (
              <option key={option.value} value={option.value}>
                {option.key}
              </option>
            ))}
          </select>
        </div>
        <div className="column is-full has-text-centered">
          <button
            className="button is-primary is-light is-focused"
            onClick={handleLanguageSelect}
          >
            <p className="montserrat-text-logo">CONTINUE</p>
          </button>
        </div>
      </div>
    </div>
  );

  // Main render
  if (!state.streaming) {
    return (
      <div className="min-h-screen">
        <section className="section is-large">
          <div className="columns is-multiline is-mobile is-centered">
            <p>
              {state.unknownBroadcast
                ? "Unknown Broadcast"
                : "Stream has not started yet"}
            </p>
          </div>
        </section>
      </div>
    );
  }

  return (
    <div className="container">
      {state.chosenLanguage ? (
        <section className="section">
          <div className="container has-text-centered">
            {renderControls()}
            {renderTextArea()}
            <div className="column has-text-centered">
              <button
                className="button is-warning"
                onClick={handleLanguageChange}
              >
                <p className="montserrat-text-logo">CHANGE LANGUAGE</p>
              </button>
            </div>
          </div>
        </section>
      ) : (
        <div className="min-h-screen">{renderLanguageSelect()}</div>
      )}
    </div>
  );
}
