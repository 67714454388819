import { useEffect, useRef, useState } from "react";

export const DeviceList = () => {
  let audio = useRef([]);
  const [gotDevices, setGotDevices] = useState(false);
  const [selectedDevice, setSelectedDevice] = useState("");
  const [volume, setVolume] = useState(0);

  // Audio context refs
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const sourceRef = useRef(null);
  const streamRef = useRef(null);
  const animationFrameRef = useRef(null);

  const startVolumeMeter = async (deviceId) => {
    try {
      // Stop any existing stream
      if (streamRef.current) {
        streamRef.current.getTracks().forEach((track) => track.stop());
      }

      // Get stream for selected device
      const stream = await navigator.mediaDevices.getUserMedia({
        audio: { deviceId: deviceId ? { exact: deviceId } : undefined },
      });

      streamRef.current = stream;

      // Set up audio context and analyzer
      audioContextRef.current = new (window.AudioContext ||
        window.webkitAudioContext)();
      analyserRef.current = audioContextRef.current.createAnalyser();
      sourceRef.current =
        audioContextRef.current.createMediaStreamSource(stream);

      sourceRef.current.connect(analyserRef.current);

      // Configure analyzer
      analyserRef.current.fftSize = 256;
      const bufferLength = analyserRef.current.frequencyBinCount;
      const dataArray = new Uint8Array(bufferLength);

      // Update volume in animation frame
      const updateVolume = () => {
        analyserRef.current.getByteFrequencyData(dataArray);
        const average =
          dataArray.reduce((acc, val) => acc + val, 0) / bufferLength;
        setVolume(Math.round((average / 255) * 100));
        animationFrameRef.current = requestAnimationFrame(updateVolume);
      };

      updateVolume();
    } catch (err) {
      console.error("Error starting volume meter:", err);
    }
  };
  const stopVolumeMeter = () => {
    if (animationFrameRef.current) {
      cancelAnimationFrame(animationFrameRef.current);
    }
    if (sourceRef.current) {
      sourceRef.current.disconnect();
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
    }
    if (streamRef.current) {
      streamRef.current.getTracks().forEach((track) => track.stop());
    }
    setVolume(0);
  };

  useEffect(() => {
    if (!navigator.mediaDevices?.enumerateDevices) {
      console.log("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(() => {
          navigator.mediaDevices
            .enumerateDevices()
            .then((devices) => {
              const audioDevices = [];
              devices.forEach((device) => {
                if (device.kind === "audioinput") {
                  audioDevices.push(device);
                }
              });
              audio.current = audioDevices;
              if (audioDevices.length > 0) {
                setSelectedDevice(audioDevices[0].deviceId);
                startVolumeMeter(audioDevices[0].deviceId);
              }
            })
            .then(() => {
              console.log(audio.current);
              setGotDevices(true);
            })
            .catch((err) => {
              console.error(`${err.name}: ${err.message}`);
            });
        })
        .catch((err) => {
          console.log(err);
          setGotDevices(true);
          audio.current = "No Audio";
        });
    }

    // Cleanup function
    return () => {
      stopVolumeMeter();
    };
  }, []);

  const handleDeviceChange = (e) => {
    const newDeviceId = e.target.value;
    setSelectedDevice(newDeviceId);
    stopVolumeMeter();
    startVolumeMeter(newDeviceId);
  };

  return (
    <div>
      <h3 className="title is-5">Device List</h3>
      {gotDevices && audio.current !== "No Audio" ? (
        <>
          <div className="field">
            <div className="control">
              <div className="select is-primary">
                <select value={selectedDevice} onChange={handleDeviceChange}>
                  {audio.current.map((device) => (
                    <option key={device.deviceId} value={device.deviceId}>
                      {device.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <p>Input Volume: {volume}%</p>
          <progress className="progress is-primary" value={volume} max="100">
            {volume}%
          </progress>
        </>
      ) : (
        <>
          {!gotDevices && audio.current !== "No Audio" ? (
            <progress className="progress is-small is-primary" max="100">
              15%
            </progress>
          ) : (
            <div>No Microphone found</div>
          )}
        </>
      )}
    </div>
  );
};
